<template>
  <div class="txffc_wrap">
    <div class="tops">
      <div class="result_wrap" @click="$router.push('/GameRecord?type=' + type)">
        <div class="title">
          <span class="expect">{{ lastConfig.expect }}</span>
          <span class="text">{{ $t("game.stage") }}</span>
        </div>
        <slot name="money"></slot>
        <div class="status">
          <div class="line1">
            <div class="ball_wrap" v-for="(item, index) in (lastConfig.open || {}).dwd" :key="index">
              <div class="ball">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="deadline_wrap">
        <div class="title">
          <span class="expect">{{ currentConfig.expect }}</span>
          <span class="text">{{ $t("game.stage") }}</span>
        </div>
        <div class="time">{{ timeCount }}</div>
      </div>
    </div>
    <div class="top_icon">
      <van-icon name="arrow-down" color="var(--light)" size="16" @click="$router.push('/GameRecord?type=' + type)" />
    </div>
    <!-- <div class="broadcast">
      <div class="title">{{ $t("game.broadcast.title") }}</div>
      <div class="content">
        <div class="table_header">
          <div class="th">{{ $t("game.broadcast.th_tradeUser") }}</div>
          <div class="th">{{ $t("game.broadcast.th_tradeType") }}</div>
          <div class="th">{{ $t("game.broadcast.th_winAmount") }}</div>
        </div>
        <marquee behavior="scroll" direction="up" scrollamount="3" class="marquee">
          <div class="table_body" v-for="(item, index) in noticeArr" :key="`marquee_${index}`">
            <div class="td">{{ item.name }}</div>
            <div class="td">{{ getStatusText(item.type) }}</div>
            <div class="td">{{ item.money }}</div>
          </div>
        </marquee>
      </div>
    </div>
    <div class="choose_title">{{ $t("game.chooseGameTitle") }}</div> -->
  </div>
</template>

<script>
import dataDetail from "@/utils/dataDetail";
import intRandom from "@/utils/random";
import { Icon } from "vant";
const words = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
];
const wordsLen = words.length;
function randomUpper() {
  return words[intRandom(wordsLen - 1)].toUpperCase();
}
function randomLower() {
  return words[intRandom(wordsLen - 1)];
}
function randomAllWords() {
  const probability = intRandom(1);
  if (probability < 1) {
    return randomUpper();
  }
  return randomLower();
}
function randomSymbols() {
  return symbols[intRandom(symbolsLen - 1)];
}
function randomAllTypeItem() {
  const probability = intRandom(2);
  switch (probability) {
    case 0:
      return randomLower();
    case 1:
      return randomUpper();
    case 2:
      return intRandom(9).toString();
    default:
      break;
  }
}
function randomLowerAndNumber() {
  const probability = intRandom(1);
  switch (probability) {
    case 0:
      return randomLower();
    case 1:
      return intRandom(9).toString();
    default:
      break;
  }
}
export default {
  props: {
    timeCount: {
      type: String,
      default: "00:00:00",
    },
    currentConfig: {
      type: Object,
      default: () => ({}),
    },
    lastConfig: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: ""
    }
  },
  components: {
    [Icon.name]: Icon,
  },
  data() {
    return {
      noticeArr: [],
    };
  },
  created() {
    this.initNotice();
  },
  methods: {
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.single");
        case "双":
          return this.$t("open.double");
        default:
          return "";
      }
    },
    plusText(index) {
      if (!this.lastConfig.open) return "";
      if (index < this.lastConfig.open.dwd.length - 1) {
        return "+";
      }
      return "=";
    },
    randName() {
      const word1 = randomAllTypeItem();
      const wordArr = [];
      for (let i = 0; i < 3; i++) {
        wordArr.push(randomLowerAndNumber());
      }
      const res = `${word1}${wordArr.join("")}****`;
      return res;
    },
    randomType() {
      const probability = intRandom(3);
      switch (probability) {
        case 0:
          return "大";
        case 1:
          return "小";
        case 2:
          return "单";
        default:
          return "双";
      }
    },
    randomMoney() {
      const probability = intRandom(100);
      if (probability < 60) {
        return intRandom([3000, 50000]);
      }
      return intRandom([5000, 200000]);
    },
    initNotice() {
      const res = [];
      for (let i = 0; i < 128; i++) {
        res.push({
          name: this.randName(),
          type: this.randomType(),
          money: this.randomMoney(),
        });
      }
      this.noticeArr = res;
    },
  },
};
</script>

<style lang="less" scoped>
@Color: var(--theme);
@wrapPadding: calc(12rem / 16);
@comRadius: 10px;

.txffc_wrap {
  padding: calc(10rem / 16);

  .tops {
    display: flex;

    >div {
      flex: 0 0 50%;
    }

    .deadline_wrap {
      text-align: center;

      .title {
        font-size: calc(12rem / 16);

        .expect {
          color: var(--light);
          font-size: calc(14rem / 16);
        }

        .text {
          font-size: calc(14rem / 16);
          color: var(--light);
          margin-left: calc(5rem / 16);
        }
      }

      .time {
        color: #fea931;
        font-size: calc(24rem / 16);
        margin-top: calc(8rem / 16);
      }
    }

    .result_wrap {
      .deadline_wrap();
      .title {
        font-size: calc(12rem / 16);

        .expect {
          color: #ff005a;
          font-size: calc(14rem / 16);
        }
      }
      .status {
        display: flex;
        justify-content: center;
        .line1 {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          @ballSize: calc(26rem / 16);
          display: flex;
          flex-wrap: wrap;
          width: calc(@ballSize * 5 + 10px);

          .ball_wrap {
            // display: inline-block;
            .ball {
              width: @ballSize;
              line-height: @ballSize;
              text-align: center;
              margin: 1px;
              border-radius: 50%;
              background-image: url("../../../../assets/icon/game_ball.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              display: inline-block;
            }

            .plus {
              // margin: 1px;
              display: inline-block;
              color: var(--textColor);
              font-size: calc(18rem / 16);
            }
          }

          .sum_ball {
            .sum {
              width: @ballSize;
              line-height: @ballSize;
              text-align: center;
              margin: 1px;
              border-radius: 50%;
              background-image: url("../../../../assets/icon/sum_ball.png");
              background-size: 100% 100%;
              background-repeat: no-repeat;
              display: inline-block;
            }
          }

          .icon-xiangxia {
            color: var(--theme);
            position: absolute;
            right: 0;
          }
        }

        .result {
          font-size: calc(18rem / 16);
          text-align: center;
          color: #fea931;
          margin-top: calc(10rem / 16);
        }
      }
    }
  }

  .top_icon {
    display: flex;
    justify-content: center;
  }

}
</style>