<template>
  <div class="wrap">
    <Navbar :title="configs.title || ''">
      <template #right>
        <i class="iconfont nav_tools_item" v-for="(item, index) in navTools" :key="`tool_${index}`" :class="item.icon"
          @click="item.click"></i>
      </template>
    </Navbar>
    <TxffcTop v-if="category != 'k3'" :timeCount="countText" :currentConfig="currentConfigs" :lastConfig="lastConfigs"
      :type="type">
      <template #money>
        <div class="banlace">{{ $t('reportCenter.balance') }}:{{ userInfo?.money }}</div>
      </template>
    </TxffcTop>
    <template v-else>
      <div class="top_wrap">
        <div class="right">
          <div class="text">
            <span>{{ orderLists[1] && orderLists[1].expect }}</span>
            {{ $t("game.stage") }}
          </div>
          <div class="banlace">{{ $t('reportCenter.balance') }}:{{ userInfo.money }}</div>
          <template v-if="orderLists[1] || orderLists[0]">
            <div class="status" v-if="type.indexOf('hl') !== -1">
              <img v-for="(item, index) in (orderLists[1] || orderLists[0]).open.dwd" :key="index"
                :src="require(`@/assets/icon/dice/${item}.png`)" alt="" />
            </div>
            <div class="status txffc" v-else>
              <div class="ball_wrap">
                <div class="ball" v-for="(item, index) in (orderLists[1] || orderLists[0]).open
                  .dwd" :key="index">
                  {{ item }}
                </div>
              </div>
              <div class="sum_ball">
                <span class="label">{{ $t("game.typeHz") }}</span>
                <span class="sum">{{
                  (orderLists[1] || orderLists[0]).open.zh.zh
                }}</span>
                <span class="result">{{
                  `${getStatusText(
                    (orderLists[1] || orderLists[0]).open.zh.dx
                  )} / ${getStatusText(
                    (orderLists[1] || orderLists[0]).open.zh.ds
                  )}`
                }}</span>
              </div>
            </div>
          </template>
        </div>
        <div class="left">
          <div class="text">
            <span>{{ orderLists[0] && orderLists[0].expect }}</span>
            {{ $t("game.stage") }}
          </div>
          <div class="time">{{ countText }}</div>
        </div>
      </div>
      <div class="top_icon">
        <van-icon name="arrow-down" color="var(--light)" size="16" @click="$router.push('/GameRecord?type=' + type)" />
      </div>
      <div class="game_desc">{{ $t('game.k3') }}</div>
    </template>
    <!-- category==pk -->
    <template v-if="category == 'pk'">
      <PK :pkResult="pkResult" @submit="pkSubmit" ref="pkRef" />
    </template>
    <!-- category==k3 -->
    <div class="content_wrap" v-else-if="category == 'k3'">
      <div class="select_wrap">
        <div class="select_item" v-for="(item, index) in selectTypes" :key="index"
          :class="!!selectArr.find((a) => a == item.value) && 'active'" @click="select(item)">
          <p>{{ item.title }}</p>
          <p>{{ (dxdsArr[index] && dxdsArr[index]?.odds) || 0 }}</p>
        </div>
        <div class="select_item" v-for="(item, index) in games" :key="`num_${index}`"
          :class="!!numSelectArr.find((a) => a == item.title) && 'active'" @click="numSelect(item)">
          <p>{{ item.title }}</p>
          <p>{{ item.odds || 0 }}</p>
        </div>
      </div>
    </div>

    <div class="content_wrap" v-else>
      <div class="newGame">
        <div class="gameLists" v-for="index in 5" :key="index">
          <div class="list_title">{{ $t('list_tableNumber' + index) }}</div>
          <div class="list">
            <div class="list_value" :class="newDxdsCode[index - 1].includes(value.value) ? 'list_active' : ''"
              v-for="value, num in newselectTypes" :key="num" @click="newDxdsClick(value, index, num)">
              <div class="title">{{ value.title }}</div>
              <div class="value">{{ newResultDxds['dxds']?.odds }}</div>
            </div>
          </div>
        </div>
        <div class="all_title">{{ $t('list_tableAll') }}</div>
      </div>
      <div class="select_wrap" :style="{ marginTop: 0, justifyContent: 'center' }">
        <div class="select_item" v-for="(item, index) in selectTypes" :key="index"
          :class="!!selectArr.find((a) => a == item.value) && 'active'" @click="select(item)">
          <p>{{ item.title }}</p>
          <p>{{ (dxdsArr[index] && dxdsArr[index]?.odds) || 0 }}</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="input_wrap" v-if="isShowFooter || showInput || getNewDxdsLength()">
        <div class="left">
          <span>{{
            `${$t("game.perPrice.text")} ${verConfig.moneyType}:`
          }}</span>
          <input v-model="perPrice" type="number" :placeholder="$t('input.permoney.placeholder')" />
        </div>
        <div class="clear" @click="clear">
          <div class="icon">
            <van-icon name="delete-o" color="#fea931" size="20" />
          </div>
          <div class="text">{{ $t("btn.clear") }}</div>
        </div>
      </div>
      <div class="bottom">
        <div class="right">
          <span>{{ `${$t("balance.text")}:` }}</span>
          <span class="price">{{ userInfo.money || "0.00" }}</span>
        </div>
        <div class="buy" @click="toConfirmMatch">{{ $t("btn.match") }}</div>
      </div>
    </div>
    <div class="right_popup_wrap">
      <van-popup v-model="rightShow" position="top">
        <div class="tool_wrap">
          <div class="tool_item" v-for="(item, index) in gameArr" :key="index"
            :style="{ borderBottom: index === gameArr.length - 1 ? '0' : '' }" @click="toolClick(item)">
            {{ item.title }}
          </div>
        </div>
      </van-popup>
    </div>
    <van-popup class="buy_pop" v-model="buyShow" closeable close-icon-position="top-left" position="bottom">
      <div class="title">{{ $t("popup.title.buyConfirm") }}</div>
      <div class="content">
        <div class="li">
          <div class="left">{{ configs.title }}:</div>
          <div class="right">
            {{
              (orderLists[0] && orderLists[0].expect) ||
              "" + $t("popup.text.data")
            }}
          </div>
        </div>
        <div class="li">
          <div class="left">{{ $t("popup.title.matchAmount") }}:</div>
          <div class="right" style="color: #ff0000">
            <template v-if="category == 'pk'">
              {{ $money(pkHlDxdsNum * (this.perPrice || 0), false) }}
            </template>
            <template v-else>
              {{ $money((matchAmount + getNewDxdsLength()) * (this.perPrice || 0), false) }}
            </template>
          </div>
        </div>
        <template v-if="category == 'pk'">
          <div class="li">
            <div class="left">{{ $t("popup.title.matchContent") }}:</div>
            <div class="right rights">
              <template v-for="(item, index) in PkArr">
                <span v-if="item !=''">
                  <div class="right_new" v-if="getStatusTexts(item).length > 0">
                    <span class="number">{{ index + 1 }}</span>&nbsp;
                    <div class="new_list" v-for="(value, num) in getStatusTexts(item)">
                      <span class="value">{{ getStatusText(value) }}</span>
                      <span class="icon">,</span>
                    </div>
                  </div>
                </span>
              </template>
            </div>
          </div>
        </template>
        <div class="li" v-else-if="category == 'k3'">
          <div class="left">{{ $t("popup.title.matchContent") }}:</div>
          <div class="right">
            <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in [...selectArr, ...numSelectArr]"
              :key="index">
              {{ getStatusText(item) }}&nbsp;
            </span>
          </div>
        </div>
        <!-- v-else-if="category != 'k3'" -->
        <template v-else>
          <div class="title">{{ $t("popup.title.matchContent") }}:</div>
          <div class="li" v-for="(item, index) in txffcGames" :key="`content_${index}`"
            v-show="(txffcSelectData[index] || []).length">
            <div class="left">{{ $t(`game.fiveBall.${index}`) }}:</div>
            <div class="right">
              <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in txffcSelectData[index] || []"
                :key="index">
                {{ getStatusText(item) }}&nbsp;
              </span>
            </div>
          </div>
          <div class="li" v-if="selectArr.length">
            <div class="left">{{ $t("game.typeHz") }}:</div>
            <div class="right">
              <span style="margin-left: calc(5rem / 16)" v-for="(item, index) in [...selectArr]" :key="index">
                {{ getStatusText(item) }}&nbsp;
              </span>
            </div>
          </div>
          <div class="li" v-if="getNewDxdsLength()">
            <div class="left">{{ $t("game.typeHz") }}:</div>
            <div class="right">
              <span v-for="(item, index) in newDxdsCode" :key="index">
                <div class="right_new" v-if="getStatusTexts(item).length > 0">
                  <span class="number">{{ index + 1 }}</span>&nbsp;
                  <div class="new_list" v-for="(value, num) in getStatusTexts(item)">
                    <span class="value">{{ getStatusText(value) }}</span>
                    <span class="icon">,</span>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </template>
      </div>
      <div class="hint">
        {{ $t("hint.confirmBuy") }}
      </div>
      <div class="btn_wrap">
        <div class="btn" @click="toMatch">{{ $t("popup.btn.match") }}</div>
      </div>
    </van-popup>
    <van-popup class="buy_pop" v-model="orderShow" closeable position="bottom" :style="{ maxHeight: '50vh' }">
      <div class="sticky">
        <div class="title">
          {{ $t("popup.title.matchResult") }}
          <div class="icon">
            <van-icon @click="orderShow = false" name="cross" size="22" color="#c8c9cc" />
          </div>
        </div>
        <div class="title_bar">
          <div class="li">{{ $t("popup.orderId") }}</div>
          <div class="li">{{ $t("open.result") }}</div>
        </div>
      </div>
      <div class="list_wrap">
        <div class="li_wrap" v-for="(item, index) in orderLists.filter(
          (a) => Object.keys(a.open).length > 0
        )" :key="index">
          <div class="li">{{ item.expect }}</div>
          <div class="li">
            <template v-if="type.indexOf('hl') !== -1">
              <img v-for="(r_item, r_index) in item.open.dwd" :key="'result' + index + r_index"
                :src="require(`@/assets/icon/dice/${r_item}.png`)" alt="" />
            </template>
          </div>
        </div>
      </div>
    </van-popup>
    <Dialog v-model="showRule" :title="$t('game.rule.title')" show-cancel-button :show-confirm-button="false"
      :cancel-button-text="$t('btn.cancel')">
      <div v-html="ruleContent"></div>
    </Dialog>
  </div>
</template>

<script>
import { router } from '@/router'
import { $post, $get } from "@/utils/request";
import { post } from "@/utils/newRequest";
import Navbar from "@/components/JNav";
import TxffcTop from "./components/txffcTop.vue";
import PK from "./components/pk.vue";
import {
  NavBar,
  cell,
  icon,
  field,
  Dialog,
  Toast,
  Tab,
  Tabs,
  Popup,
} from "vant";

const arr = {
  "dd": 3,
  ssc: 5,
  pk: 10,
  x5: 5,
  k3: 3
}
export default {
  components: {
    [NavBar.name]: NavBar,
    [icon.name]: icon,
    [Popup.name]: Popup,
    Toast,
    Navbar,
    Dialog: Dialog.Component,
    TxffcTop,
    PK
  },

  data() {
    this.navTools = [
      // {
      //   icon: "icon-f4f5f8",
      //   title: "rule",
      //   click: () => {
      //     this.showRule = true;
      //   },
      // },
      // {
      //   icon: "icon-guizeguanli",
      //   title: "record",
      //   click: () => {
      //     this.toRecord();
      //   },
      // },
      {
        icon: "icon-gengduo",
        title: "more",
        click: () => {
          this.rightShow = !this.rightShow;
        },
      },
    ];
    this.selectTypes = [
      { title: this.$t("open.big"), value: "大" },
      { title: this.$t("open.small"), value: "小" },
      { title: this.$t("open.single"), value: "单" },
      { title: this.$t("open.double"), value: "双" },
    ];
    this.newselectTypes = [
      { title: this.$t("open.big"), value: "大", methods: 'hz-hz-big' },
      { title: this.$t("open.small"), value: "小", methods: 'hz-hz-small' },
      { title: this.$t("open.single"), value: "单", methods: 'hz-hz-single' },
      { title: this.$t("open.double"), value: "双", methods: 'hz-hz-dual' },
    ];
    return {
      configs: {},
      rightShow: false,
      orderLists: [],
      selectArr: [],
      perPrice: "",
      userInfo: {},
      interval: null,
      countText: "00:00:00",
      countTime: 0,
      buyShow: false,
      orderShow: false,
      games: [],
      dxdsConfig: {},
      numSelectArr: [],
      ruleContent: "",
      showRule: false,
      txffcGames: [],
      txffcSelectData: {},
      currentGame: {},
      dxdsArr: [],
      isFindIndex: '',
      newDxdsArr: [],
      newResultDxds: {},
      newDxdsCode: ['', '', '', '', ''],
      timer: null,
      type: "",
      pkResult: {},
      isShowFooter: false,
      PkArr: [],
      addTime: null,
      pkHlDxdsArr: [new Array(10).fill(''), new Array(3).fill('')],
    };
  },
  created() {
    const { type } = this.$route.query;
    this.type = type;
    this.stopTimer();
    this.$nextTick(() => {
      this.getRoomData();
      this.getOrders();
    })
    this.getUserInfo();
  },
  computed: {
    // type() {
    //   const { type } = this.$route.query;
    //   return type;
    // },
    category() {
      const { category } = this.$route.query;
      return category
    },
    merchants() {
      return this.$store.getters.AllGameList
    },
    gameArr() {
      return this.merchants.filter(item => item.category == this.category) || [];
    },
    tools() {
      return [
        // { title: this.$t("tab.record"), fun: () => this.toRecord() },
        { title: this.$t("btn.recharge"), fun: () => this.toRecharge() },
        { title: this.$t("btn.withdraw"), fun: () => this.toWithdraw() },
        // { title: this.$t("btn.service"), fun: () => this.toService() },
      ];

    },
    showInput() {
      let flag = false;
      for (const key in this.txffcSelectData) {
        if (Object.hasOwnProperty.call(this.txffcSelectData, key)) {
          const arr = this.txffcSelectData[key];
          if (arr.length) {
            flag = true;
          }
        }
      }
      return flag || [...this.selectArr, ...this.numSelectArr].length;
    },
    txffcMatchNum() {
      let num = 0;
      for (const key in this.txffcSelectData) {
        if (Object.hasOwnProperty.call(this.txffcSelectData, key)) {
          const item = this.txffcSelectData[key] || [];
          num += item.length;
        }
      }
      return num;
    },
    matchAmount() {
      let num = this.txffcMatchNum;
      num += [...this.selectArr, ...this.numSelectArr].length;
      return num;
    },
    currentConfigs() {
      return this.currentGame;
    },
    lastConfigs() {
      return this.orderLists[1] || this.orderLists[0] || {};
    },
    pkHlDxdsNum() {
      return this.PkArr.reduce((sum, item) => sum + item.length, 0)
    }
  },
  methods: {
    pkSubmit(arr) {
      this.PkArr = arr;
      const index = arr.filter(obj => obj != '');
      this.isShowFooter = index.length > 0 ? true : false;
      arr.forEach((item, index) => {
        if (item.includes('龙') || item.includes('虎')) {
          this.pkHlDxdsArr[1][index] = item.split('').filter(char => char === '龙' || char === '虎').join('');
        }else{
          this.pkHlDxdsArr[1][index] = ''
          this.pkHlDxdsArr[1].length = 3
        }
        this.pkHlDxdsArr[0][index] = item.replace(/龙|虎/g, '')
      });
    },
    toolClick(item) {
      this.type = item.type;
      this.clear();
      this.stopTimer();
      this.$nextTick(() => {
        this.getRoomData();
        this.getOrders();
      })
      this.rightShow = false;
    },
    getNewDxdsLength() {
      return this.newDxdsCode.reduce((acc, value) => acc + value.length, 0)
    },
    newDxdsClick(item, index, num) {
      if (this.newDxdsCode[index - 1].includes(item.value)) {
        const str = this.newDxdsCode[index - 1].replace(item.value, '');
        this.newDxdsCode[index - 1] = str
      } else {
        this.newDxdsCode[index - 1] += item.value
      }

    },
    toRecord() {
      this.$router.push("cart");
    },
    toRecharge() {
      this.$router.push("/new-recharge");
    },
    toWithdraw() {
      this.$router.push("/new-withdraw");
    },
    // 跳转客服
    toService() { },
    // 获取房间信息
    async getRoomData() {
      // this.dxdsArr = [];
      try {
        const res = await $get("/lottery/setting", {
          type: this.type,
          explain: 1,
        });
        const { ret, msg, data } = res.data;
        this.configs = data[0];
        // this.games = [];
        this.ruleContent = this.configs.method_explain;
        // if (this.category == 'pk') {
        //   const newArr = data[0].odds;
        //   this.newDxdsArr = newArr;
        //   const result = {};
        //   for (const item of newArr) {
        //     result[item.method] = { ...item };
        //   }
        //   this.pkResult = result
        //   return
        // }
        // if (this.category != 'k3') {
        //   const newArr = data[0].odds;
        //   this.isFindIndex = newArr.findIndex(item => item.method == 'dxds') >= 0 ? true : false;
        //   this.newDxdsArr = newArr;
        //   const result = {};
        //   for (const item of newArr) {
        //     result[item.method] = { ...item };
        //   }
        //   this.newResultDxds = result;
        // }
        // if (this.category != 'k3') {
        //   const item = data[0].oddtrees[0].items.hz;
        //   for (const key in item) {
        //     if (Object.hasOwnProperty.call(item, key)) {
        //       const value = item[key];
        //       if (value) {
        //         switch (key) {
        //           case 'big':
        //             value.title = "大"
        //             break;
        //           case 'small':
        //             value.title = "小"
        //             break;
        //           case 'single':
        //             value.title = "单"
        //             break;
        //           case 'dual':
        //             value.title = "双"
        //             break;
        //           default:
        //             break;
        //         }
        //         value.method = `hz-hz-${key}`
        //         this.dxdsArr.push(value)
        //       }
        //     }
        //   }
        //   return;
        // }
        // if(this.category == 'k3'){
        //   const games = data[0].oddtrees[0].items.hz;
        //   const dxdsKeys = ['big', 'small', 'single', 'dual'];
        //   const keys = Object.keys(games).filter(item => (item.indexOf('dxds') < 0 && dxdsKeys.indexOf(item) < 0));
        //   for (const key in games) {
        //     if (Object.hasOwnProperty.call(games, key)) {
        //       const value = games[key];
        //       if (value) {
        //         switch (key) {
        //           case 'big':
        //             value.title = "大"
        //             break;
        //           case 'small':
        //             value.title = "小"
        //             break;
        //           case 'single':
        //             value.title = "单"
        //             break;
        //           case 'dual':
        //             value.title = "双"
        //             break;
        //           default:
        //             break;
        //         }
        //         if (dxdsKeys.includes(key)) {
        //           value.method = `hz-hz-${key}`
        //           this.dxdsArr.push(value)
        //         }
        //       }
        //     }
        //   }
        //   for (const key of keys) {
        //     const value = games[key];
        //     value.title = key;
        //     value.method = `hz-hz-${key}`;
        //     this.games.push(value);
        //   }
        // }
      } catch (error) { }
    },
    // 获取订单
    async getOrders(pageSize = 2) {
      Toast.loading();
      this.dxdsArr = [];
      // this.games = [];
      try {
        const res = await $get("/lottery/lists", {
          type: this.type,
          pagesize: pageSize,
        });
        const { ret, msg, data } = res.data;
        // this.orderLists = [
        //   ...data.lists.filter((item) => item.open.dwd),
        //   ...this.orderLists,
        // ];
        this.orderLists = data.lists;
        const current = data.lists[0];
        if (this.category == 'pk') {
          const newArr = current.odds;
          this.newDxdsArr = newArr;
          const result = {};
          for (const item of newArr) {
            result[item.method] = { ...item };
          }
          this.pkResult = result
          return
        }
        if (this.category != 'k3') {
          const newArr = current.odds;
          this.isFindIndex = newArr.findIndex(item => item.method == 'dxds') >= 0 ? true : false;
          this.newDxdsArr = newArr;
          const result = {};
          for (const item of newArr) {
            result[item.method] = { ...item };
          }
          this.newResultDxds = result;
        }
        if (this.category != 'k3') {
          const item = current.oddtrees[0].items.hz;
          for (const key in item) {
            if (Object.hasOwnProperty.call(item, key)) {
              const value = item[key];
              if (value) {
                switch (key) {
                  case 'big':
                    value.title = "大"
                    break;
                  case 'small':
                    value.title = "小"
                    break;
                  case 'single':
                    value.title = "单"
                    break;
                  case 'dual':
                    value.title = "双"
                    break;
                  default:
                    break;
                }
                value.method = `hz-hz-${key}`
                this.dxdsArr.push(value)
              }
            }
          }
        }
        if(this.category == 'k3'){
          const games = current.oddtrees[0].items.hz;
          const dxdsKeys = ['big', 'small', 'single', 'dual'];
          const keys = Object.keys(games).filter(item => (item.indexOf('dxds') < 0 && dxdsKeys.indexOf(item) < 0));
          for (const key in games) {
            if (Object.hasOwnProperty.call(games, key)) {
              const value = games[key];
              if (value) {
                switch (key) {
                  case 'big':
                    value.title = "大"
                    break;
                  case 'small':
                    value.title = "小"
                    break;
                  case 'single':
                    value.title = "单"
                    break;
                  case 'dual':
                    value.title = "双"
                    break;
                  default:
                    break;
                }
                if (dxdsKeys.includes(key)) {
                  value.method = `hz-hz-${key}`
                  this.dxdsArr.push(value)
                }
              }
            }
          }
          for (const key of keys) {
            const value = games[key];
            value.title = key;
            value.method = `hz-hz-${key}`;
            this.games.push(value);
          }
        }
        this.currentGame = current;
        this.addInterval(current.countdown, current);
        Toast.clear();
      } catch (error) {
        Toast.clear();
      }
    },
    // 状态判断
    getStatus(item) {
      if (!item) return;
      const { status, open } = item;
      switch (status) {
        case "待开奖":
          return this.$t("status.waiting");
          break;
        case "已开奖":
          const data = open.dxds[0];
          // const nums = open.dwd;
          // for(let i=0,len=nums.length;i<len;i++){
          //   const i=0;
          // }
          let res = "";
          res = this.getStatusText(data.dx);
          res += "\n" + this.getStatusText(data.ds);
          return res;
          break;
        case "开奖出错":
          return this.$t("status.fail");
          break;
        default:
          return "";
          break;
      }
    },
    getStatusText(text) {
      switch (text) {
        case "大":
          return this.$t("open.big");
          break;
        case "小":
          return this.$t("open.small");
        case "单":
          return this.$t("open.single");
        case "双":
          return this.$t("open.double");
        case "龙":
          return this.$t("open.loong");
        case "虎":
          return this.$t("open.hu");
        default:
          return text;
      }
    },
    getStatusTexts(text) {
      return text.split("")
      // switch (text) {
      //   case "大":
      //     return this.$t("open.big");
      //     break;
      //   case "小":
      //     return this.$t("open.small");
      //   case "单":
      //     return this.$t("open.single");
      //   case "双":
      //     return this.$t("open.double");
      //   default:
      //     return text;
      // }
    },
    // 选择
    select({ value }) {
      const b = this.selectArr.find((a) => a == value);
      if (!b) {
        this.selectArr.push(value);
      } else {
        const newArr = [...this.selectArr];
        this.selectArr = newArr.filter((item) => item !== value);
      }
    },
    numSelect({ title }) {
      const b = this.numSelectArr.find((a) => a == title);
      if (!b) {
        this.numSelectArr.push(title);
      } else {
        const newArr = [...this.numSelectArr];
        this.numSelectArr = newArr.filter((item) => item !== title);
      }
    },
    txffcSelect({ value }, index) {
      let itemData = this.txffcSelectData[index];
      if (itemData) {
        const b = itemData.find((a) => a == value);
        if (!b) {
          itemData.push(value);
        } else {
          const newArr = [...itemData];
          itemData = newArr.filter((item) => item !== value);
        }
      } else {
        itemData = [value];
      }
      this.$set(this.txffcSelectData, index, itemData);
    },
    // 获取用户信息
    getUserInfo() {
      if (!this.userInfo.level) {
        this.$api.getUserInfo((res) => {
          this.userInfo = res;
        });
      }
    },
    clear() {
      this.perPrice = "";
      this.selectArr = [];
      this.numSelectArr = [];
      this.newDxdsCode = ['', '', '', '', '']
      this.PkArr = [];
      this.pkResult = {};
      this.isShowFooter = false;
      this.pkHlDxdsArr =[new Array(10).fill(''), new Array(3).fill('')];
      this.$refs.pkRef && this.$refs.pkRef.clear();
    },
    toConfirmMatch() {
      if (!this.showInput && !this.getNewDxdsLength() && !this.isShowFooter) {
        this.$toast(this.$t("hint.toAddOrder"));
        return;
      }
      if (this.perPrice == "") {
        this.$toast(this.$t("hint.toPerPrice"));
        return;
      }
      if (!this.countTime) {
        this.$toast(this.$t("hint.opening"));
        return;
      }
      this.buyShow = true;
    },
    async toMatch() {
      let promiseArr = [];
      if (this.category == 'pk') {
        if (Number(this.userInfo.money) < this.pkHlDxdsNum * (this.perPrice || 0)) return this.$toast(this.$t("hint.moneyLess"));
        if (!this.countTime) return this.$toast(this.$t("hint.opening"));
        const firstNum = this.pkHlDxdsArr[0].reduce((sum, item) => sum + item.length, 0);
        const secondNum = this.pkHlDxdsArr[1].reduce((sum, item) => sum + item.length, 0);
        if(firstNum){
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "dxds",
              code: this.pkHlDxdsArr[0].join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        if(secondNum){
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "lh",
              code: this.pkHlDxdsArr[1].join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
      } else {
        if (!([...this.selectArr, ...this.numSelectArr].length + this.txffcMatchNum + this.getNewDxdsLength()) || this.perPrice == "") return;
        if (Number(this.userInfo.money) < (this.matchAmount + this.getNewDxdsLength()) * (this.perPrice || 0)) {
          this.$toast(this.$t("hint.moneyLess"));
        }
        if (!this.countTime) {
          this.$toast(this.$t("hint.opening"));
          return;
        }
        let txffcMatchStr = "";
        if (this.txffcMatchNum) {
          const arr = [];
          for (let i = 0, len = this.txffcGames.length; i < len; i++) {
            arr.push(this.txffcSelectData[i] || []);
          }
          let num = 0;
          txffcMatchStr = arr.reduce((prev, current) => {
            if (num == 0) {
              num++;
              return current.join("");
            }
            return prev + `,${current.join("")}`;
          }, "");
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "dxds",
              code: txffcMatchStr,
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        if (!!this.getNewDxdsLength()) {
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              expect: this.currentGame.expect,
              method: 'dxds',
              code: this.newDxdsCode.join(','),
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        for (let i = 0, len = this.selectArr.length; i < len; i++) {
          const items = this.selectArr[i]
          const obj = this.dxdsArr.find(item => item.title == items);
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: obj.method,
              code: items,
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
        for (let i = 0, len = this.numSelectArr.length; i < len; i++) {
          const item = this.numSelectArr[i];
          promiseArr.push(
            post("/lottery/send", {
              type: this.type,
              // expect: this.orderLists[0].expect,
              expect: this.currentGame.expect,
              method: "hz-hz-" + item,
              code: item,
              money: this.perPrice,
              mtype: "yuan",
            })
          );
        }
      }
      Promise.all(promiseArr).then((res) => {
        this.buyShow = false;
        this.clear();
        this.$toast(this.$t("hint.matchSuccess"));
        this.getUserInfo();
      }).catch((err) => {
        // this.$toast(this.$t("hint.matchFail"));
        this.$toast(err.msg || this.$t("hint.matchFail"))
      });
    },
    addInterval(countTime, item) {
      if (!Number(countTime)) {
        this.countText = this.$t("status.open");
        this.addTime = setTimeout(() => {
          this.getOrders();
        }, (item.opentime - item.endtime) * 1000);
        return;
      }
      this.countTime = countTime;
      this.intervalFun();
      this.interval = setInterval(() => {
        this.countTime--;
        this.intervalFun();
      }, 1000);
    },
    format(num) {
      if (num >= 10) return num;
      else return "0" + num;
    },
    intervalFun() {
      const hourUnit = 60 * 60;
      const minuteUnit = 60;
      const secondUnit = 1;
      const targetH = Math.floor(this.countTime / hourUnit);
      const targetM = Math.floor((this.countTime % hourUnit) / minuteUnit);
      const targetS = Math.floor(
        ((this.countTime % hourUnit) % minuteUnit) / secondUnit
      );
      if (!this.countTime) {
        clearInterval(this.interval);
        this.getOrders();
        this.getRoomData();
      }
      this.countText = `${this.format(targetH)}:${this.format(
        targetM
      )}:${this.format(targetS)}`;
    },
    stopTimer() {
      this.countTime = 0;
      this.countText = '00:00:00';
      clearInterval(this.interval);
      clearTimeout(this.timer);
      clearTimeout(this.addTime)
      this.timer = null;
      this.interval = null;
      this.addTime = null
    }
  },
  beforeDestroy() {
    this.stopTimer()
  },
};
</script>

<style lang="less" scoped>
.wrap {
  @import url("@/assets/iconfonts/iconfont.css");
  // @bg: #e4f0fa;
  @bg: var(--bg);
  @bgBlod: var(--navTheme);
  @black: var(--navTheme);
  @activeColor: #fea931;
  min-height: 100vh;
  background-color: @bg;

  .nav_tools_item {
    margin: 0 6px;
    color: var(--light);
    font-size: 20px;

    &:nth-last-of-type(1) {
      margin-right: 0;
    }
  }

  .flex(@position: "") {
    display: flex;
    flex-direction: @position;
  }

  .ver(@position: center) {
    align-items: @position;
  }

  .hor(@position: center) {
    justify-content: @position;
  }

  .nav_left_wrap {
    display: flex;
    align-items: center;

    >.left_title {
      color: var(--textColor);
      margin-left: 5px;
    }
  }

  .right_popup_wrap {
    @topHeight: 46px;

    /deep/ .van-overlay {
      height: clac(100vh - @topHeight);
      top: @topHeight;
    }

    /deep/ .van-popup--top {
      top: @topHeight;
    }

    /deep/ .van-popup {
      top: @topHeight;
      background-color: transparent;
    }

    .tool_wrap {
      position: fixed;
      right: calc(10rem / 16);
      padding: 0 calc(10rem / 16);
      background-color: #fff;
      border-radius: calc(6rem / 16);
      z-index: 999;

      &::before {
        content: "";
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border: calc(10rem / 16) solid transparent;
        border-bottom-color: #fff;
        top: calc(-20rem / 16);
        right: calc(5rem / 16);
      }

      .tool_item {
        @height: calc(42rem / 16);
        // width: calc(100rem / 16);
        height: @height;
        line-height: @height;
        text-align: center;
        border-bottom: 1px solid #ccc;
      }
    }
  }

  .top_wrap {
    padding: calc(10rem / 16);
    display: flex;
    background: @bgBlod;

    >div {
      flex: 0 0 50%;
      text-align: center;
    }

    .left {
      .text {
        color: var(--light);
        font-size: calc(14rem / 16);
      }

      .time {
        color: @activeColor;
        font-size: calc(28rem / 16);
        line-height: calc(36rem / 16);
      }
    }

    .right {
      .text {
        font-size: calc(14rem / 16);
        color: var(--light);

        span {
          color: #ff005a;
        }
      }

      .banlace {
        display: block;
        color: #0d8ea7;
        font-size: calc(17rem / 16);
        font-weight: 700;
        margin-top: calc(2rem / 16);
        text-align: center;
        line-height: calc(32rem / 16);
      }

      .status {
        font-size: calc(12rem / 16);
        line-height: calc(36rem / 16);
        color: @activeColor;
        display: flex;
        justify-content: center;
        margin-top: 6px;
        align-items: center;
        flex-wrap: wrap;

        >img {
          width: calc(18rem / 16);
          height: calc(18rem / 16);
          margin-left: 10px;

          &:nth-of-type(1) {
            margin-left: 0;
          }
        }

        .ball_wrap {
          .ball {
            border-radius: 50%;
            width: calc(26rem / 16);
            line-height: calc(26rem / 16);
            margin: 1px;
            background: var(--theme);
            color: #000;
            display: inline-block;
            background-image: url("../../../assets/icon/game_ball.png");
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }
        }

        .sum_ball {
          border-radius: 50%;
          margin: 1px;

          >span {
            margin-left: calc(8rem / 16);

            &:nth-child(1) {
              margin-left: 0;
            }
          }
        }

        .icon-xiangxia {
          font-size: 16px;
          color: #f9982e;
          margin-left: 20px;
        }
      }

      .txffc {
        flex-direction: column;
      }
    }
  }

  .banlace {
    display: block;
    color: #0d8ea7;
    font-size: calc(17rem / 16);
    font-weight: 700;
    margin-top: calc(2rem / 16);
    text-align: center;
    line-height: calc(32rem / 16);
  }

  .top_icon {
    display: flex;
    justify-content: center;
  }

  .game_desc {
    padding: calc(15rem / 16) calc(20rem / 16) calc(5rem / 16) calc(20rem / 16);
    color: #ccc;
    font-size: calc(12rem / 16);
    text-align: center;
  }

  .content_wrap {
    padding: 0 calc(20rem / 16);
    padding-bottom: calc(136rem / 16);

    .type_title {
      color: #999;
      text-align: center;
      margin-top: calc(16rem / 16);
    }

    .newGame {
      .all_title {
        white-space: nowrap;
        text-align: center;
        color: #999;
        padding: calc(16rem / 16) 0;
      }

      .gameLists {
        .list_title {
          white-space: nowrap;
          text-align: center;
          border-radius: 10px;
          background-color: #0f1d30;
          color: #999;
          padding: calc(16rem / 16) 0;
        }

        .list {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          gap: calc(20rem / 16);

          &_value {
            border-radius: 4px;
            background: #404b5e;
            font-size: calc(18rem / 16);
            width: calc((100% - (40rem / 16)) / 3);
            display: flex;
            align-items: center;
            flex-direction: column;
            color: #ccc;
            padding: calc(10rem / 16) 0;

            .value {
              font-size: calc(10rem / 16);
              word-break: break-all;
              text-overflow: ellipsis;
              margin-top: calc(3rem / 16);
            }
          }

          .list_active {
            background-color: #0c8ba3;
            color: var(--light);
          }
        }
      }
    }

    .select_wrap {
      margin-top: calc(15rem / 16);
      display: flex;
      flex-wrap: wrap;
      gap: calc(20rem / 16);

      .select_item {
        @height: calc(46rem / 16);
        border-radius: 4px;
        background: #404b5e;
        font-size: calc(18rem / 16);
        width: calc((100% - (40rem / 16)) / 3);
        display: flex;
        align-items: center;
        flex-direction: column;
        color: #ccc;
        padding: calc(10rem / 16) 0;

        >p {
          &:nth-last-child(1) {
            font-size: calc(10rem / 16);
            word-break: break-all;
            text-overflow: ellipsis;
            margin-top: calc(3rem / 16);
          }
        }
      }

      .active {
        background-color: #0c8ba3;
        color: var(--light);
      }
    }

    .img_wrap {
      @item_gap: calc(16rem / 16);
      @img_size: calc();
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: calc(18rem / 16);

      .img_item {
        @size: calc((100% - @item_gap * 3) / 4 - 2px);
        width: @size;
        height: @size;
        margin-right: @item_gap;
        margin-bottom: @item_gap;
        border-radius: 4px;

        // border: 1px solid #f1c8c8;
        &:nth-child(4n) {
          margin-right: 0;
        }

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          margin-bottom: 0;
        }
      }
    }
  }

  .footer {
    @height: calc(50rem / 16);
    @border: 1px solid #404b5e;
    position: fixed;
    bottom: 0;
    width: 100vw;
    background-color: #fff;

    .input_wrap {
      border-top: @border;
      display: flex;
      align-items: center;
      padding: calc(5rem / 16) 0;
      @height: calc(26rem / 16);
      justify-content: space-between;

      >div {
        width: 50%;
        height: @height;
        display: flex;
        align-items: center;
      }

      .left {
        @left: calc(15rem / 16);
        padding-left: @left;
        flex-grow: 1;
        font-size: calc(14rem / 16);
        display: flex;
        align-items: center;
        color: var(--textColor);

        >input {
          text-indent: calc(5rem / 16);
          width: calc(120rem / 16);
          margin: 0 calc(12rem / 16);
          height: calc(@height - 2px);
          border-radius: 2px;
          border: 1px solid #0c8ba3;
          background: transparent;
          color: @activeColor;
        }
      }

      .clear {
        width: calc(58rem / 16);
        height: calc(100% - 16px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-left: 1px solid #0c8ba3;
        color: @activeColor;
      }
    }

    .bottom {
      @height: calc(50rem / 16);
      display: flex;
      // border-top: @border;
      height: @height;
      width: 100vw;
      align-items: center;
      justify-content: space-between;
      // background: @black;
      background: #404b5e;

      .right {
        font-size: calc(14rem / 16);
        height: fit-content;
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-left: calc(16rem / 16);
        color: var(--light);

        >.price {
          // color: #d80b2a;
          color: @activeColor;
          margin-left: calc(6rem / 16);
        }
      }

      .buy {
        width: calc(120rem / 16);
        height: 100%;
        line-height: @height;
        text-align: center;
        background: linear-gradient(179deg, #13a2ba, #087c95);
        color: var(--light);
        font-weight: bold;
      }
    }
  }

  .buy_pop {
    @title_height: calc(50rem / 16);
    background: @bg;

    .title {
      @height: @title_height;
      line-height: @height;
      height: @height;
      font-size: calc(18rem / 16);
      text-align: center;
      background: @bg;
      color: var(--light);
    }

    /deep/ .van-popup__close-icon {
      color: var(--light);
    }

    .content {
      padding: calc(12rem / 16) 0;
      margin: 0 calc(24rem / 16);
      border-bottom: 1px solid #e5e5e5;

      .li {
        display: flex;
        color: var(--light);
        font-size: calc(14rem / 16);
        padding: calc(6rem / 16);

        >div {
          .flex();
          // .ver(flex-end);
          flex-wrap: wrap;
        }

        .left {
          // min-width: calc(90rem / 16);
          white-space: nowrap;
        }

        .right {
          margin-left: calc(6rem / 16);

          .right_new {
            display: flex;
            margin-bottom: calc(5rem / 16);

            .number {
              width: calc(16rem / 16);
              height: calc(16rem / 16);
              border-radius: 50%;
              border: 1px solid var(--light);
              color: var(--light);
              display: flex;
              align-items: center;
              justify-content: center;
            }

            margin-left: calc(5rem / 16);

            .new_list:nth-last-of-type(1) {
              .icon {
                display: none;
              }
            }
          }
        }
        .rights{
          >span{
            display: flex;
          }
          .right_new{
            display: ruby;
            padding-right: calc(10rem / 16);
            padding-bottom: calc(2rem / 16);
          }
          flex-wrap: wrap;
        }
      }
    }

    .hint {
      padding: calc(12rem / 16);
      text-align: center;
      color: var(--colors);
    }

    .btn_wrap {
      padding: calc(12rem / 16);
      margin-bottom: calc(12rem / 16);

      .btn {
        color: var(--light);
        background: linear-gradient(179deg, #13a2ba, #087c95);
        font-size: calc(20rem / 16);
        text-align: center;
        line-height: calc(34rem / 16);
        padding: calc(10rem / 16) 0;
        border-radius: calc(6rem / 16);
      }
    }

    .sticky {
      position: sticky;
      top: 0;
      background-color: #fff;
      z-index: 999;

      .title {
        position: relative;

        .icon {
          position: absolute;
          height: @title_height;
          .flex();
          .ver(center);
          top: 0;
          left: 16px;
        }
      }
    }

    .title_bar {
      .flex();
      border-bottom: 1px solid #eee;

      .li {
        width: calc(100% / 3);
        text-align: center;
        line-height: calc(36rem / 16);
        color: #999;
      }
    }

    .list_wrap {
      position: relative;

      .li_wrap {
        --big: #f9982e;
        --small: #7f8ab0;
        .flex();

        .li {
          width: calc(100% / 3);
          line-height: calc(36rem / 16);
          font-size: calc(12rem / 16);
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;

          >img {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            border: 1px solid #eee;

            &:nth-child(1) {
              margin-left: 46px;
            }
          }

          &:nth-child(1) {
            color: #666;
          }
        }

        &:nth-child(2n) {
          background-color: #eee;
        }
      }
    }
  }
}
</style>